<template>
    <BT-Blade-Item
        bladeName="batch-archive"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        navigation="batch-archives"
        title="Batch Archive">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="6" sm="4">
                    <BT-Field-Entity
                        itemText="productName"
                        :itemValue="item.productID"
                        label="Product"
                        navigation="public-products"
                        single
                        useLocalCache />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Entity
                        itemText="locationName"
                        :itemValue="item.locationID"
                        label="Location"
                        navigation="public-locations"
                        single
                        useLocalCache />
                </v-col>

                <v-col cols="12" sm="4">
                    <BT-Field-Entity
                        v-if="item.originalBatchID != null"
                        label="Originated From"
                        navigation="batch-archives"
                        single
                        :itemValue="item.originalBatchID"
                        itemText="batchcode"
                        alternateText="Not Found"
                        useLocalCache />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Object
                        label="Quantity Goal"
                        textFilter="toDisplayNumber"
                        v-model="item.quantityGoal" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Object
                        label="Quantity Made"
                        textFilter="toDisplayNumber"
                        v-model="item.quantity" />
                </v-col>

                <v-col cols="12" sm="4">
                    <!-- <BT-Btn
                        bladeName="batch-tracking"
                        :id="item.id"
                        label="Tracking"
                        icon="mdi-radar" /> -->
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-String
                        label="Batchcode"
                        v-model="item.batchcode" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        label="Date Of Manufacture"
                        v-model="item.dom" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        label="Date Of Expiry"
                        v-model="item.exp" />
                </v-col>

                <v-col cols="12">
                    <BT-Btn-Row>
                        <BT-Btn
                            bladeName="batch-tracking"
                            :id="item.id"
                            label="Track"
                            icon="mdi-radar" />
                    </BT-Btn-Row>
                </v-col>

                <v-col cols="12">
                    <BT-Preload label="Activity Log">
                        <v-subheader>Activity Log</v-subheader>
                        <BT-Blade-Items
                            :canExportCSV="false"
                            :canSelect="false"
                            hideActions
                            hideBackButton
                            hideBladeHeader
                            hideHeader
                            navigation="batch-adjustment-archives"
                            :params="{ batchID: item.id }"
                            showList>
                            <template v-slot:listItem="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <span>
                                            <BT-Entity
                                                alternateText="Someone"
                                                inline
                                                navigation="users"
                                                :itemValue="item.userID"
                                                single
                                                textFilter="toUserLine"
                                                useLocalCache />
                                        </span>
                                        <span v-if="item.adjustmentType == 'Waste'" class="mr-1">wasted</span>
                                        <span v-else-if="item.adjustmentType == 'Add'" class="mr-1">added</span>
                                        <span v-else-if="item.adjustmentType == 'Remove'" class="mr-1">removed</span>
                                        <span v-else-if="item.adjustmentType == 'Hold'" class="mr-1">held back</span>
                                        <span v-else-if="item.adjustmentType == 'OnHold'" class="mr-1">held all</span>
                                        <span class="mr-1">{{ item.quantity | toDisplayNumber }}</span>
                                        <span>unit{{ item.quantity != 1 ? 's' : '' }} at</span>
                                        <span>
                                            <BT-Entity
                                                navigation="locations"
                                                inline
                                                :itemValue="item.locationID"
                                                itemText="locationName"
                                                single
                                                useLocalCache />
                                        </span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ item.createdOn | toLongDateAndTime }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </BT-Blade-Items>
                    </BT-Preload>
                </v-col>
            </v-row>

<!-- 
            <v-expansion-panels v-model="panelV" class="mt-2">
                <BT-Blade-Expansion-Items
                    :bladesData="bladesData"
                    title="Activity Log"
                    navigation="batch-adjustment-archives"
                    :params="{ batchID: item.id }"
                    showList>
                    <template v-slot:listItem="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>
                                <BT-Entity
                                    navigation="locations"
                                    :itemValue="item.locationID"
                                    itemText="locationName" />
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <BT-Entity
                                    alternateText="Someone"
                                    class="mr-1"
                                    inline
                                    navigation="users"
                                    :itemValue="item.userID"
                                    itemText="userName">
                                    {{ item.adjustmentType == 'Waste' ? ' wasted ' : (item.adjustmentType == 'Add' ? ' added ' : (item.adjustmentType == 'Remove' ? ' removed ' : (item.adjustmentType == 'Hold' ? ' held back ' : (item.adjustmentType == 'OnHold' ? ' held all ' : '?')))) }} {{ item.quantity | toDisplayNumber }} unit{{ item.quantity != 1 ? 's' : '' }}
                                </BT-Entity>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                {{ item.createdOn | toLongDateAndTime }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            {{ item.quantity | toDisplayNumber }}
                        </v-list-item-action>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels> -->
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Batch-Archive-Blade',
    components: {
        // BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
    },
    data: function() {
        return {
            // panelV: 0,
        }
    },
    props: {
        bladesData: null
    }
}
</script>